import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedModule } from 'lib-trend-core'
import { WebhookWaTemplateMessageComponent } from './webhook-integrations-wa-template-message/webhook-integrations-wa-template-message.component'
import { WebhookWaTemplateComponent } from './webhook-integrations-wa-template/webhook-integrations-wa-template.component'

@NgModule({
  declarations: [WebhookWaTemplateMessageComponent, WebhookWaTemplateComponent],
  exports: [WebhookWaTemplateMessageComponent, WebhookWaTemplateComponent],
  imports: [CommonModule, SharedModule],
  providers: [],
})
export class WaTemplateModule {}

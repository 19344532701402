<div class="container-template">
  <table class="card-attendance-msg">
    <thead class="text-align-left">
      <tr>
        <th>Nome do modelo</th>
        <th>Prévia</th>
        <th>Categoria</th>
        <th>Idioma</th>
        <th></th>
      </tr>
    </thead>
    <tr *ngFor="let template of waTemplates; let i = index">
      <td class="template-name">
        <div>{{ template.name }}</div>
      </td>
      <td class="template-preview">
        {{ template.bodyComponent?.text | slice : 0 : 50 }}...
      </td>
      <td class="template-category">
        <span *ngIf="template.category === 'MARKETING'">Marketing</span>
        <span *ngIf="template.category === 'UTILITY'">Utilitário</span>
        <span *ngIf="template.category === 'AUTHENTICATION'">Autenticação</span>
        <span *ngIf="template.category === 'SERVICE'">Serviço</span>
        <span *ngIf="template.category !== 'MARKETING' && template.category !== 'UTILITY' && template.category !== 'AUTHENTICATION' && template.category !== 'SERVICE'">
          {{ template.category }}
        </span>
      </td>
      <td class="template-language">
        <ng-container [ngSwitch]="template.language">
          <ng-container *ngSwitchCase="'pt_BR'">
            <div class="language">
              <img width="15" src="../../../../assets/icons/br.svg" alt="" />
              <h6>Português</h6>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'en_US'">
            <div class="language">
              <img
                width="15"
                src="../../../../../../../assets/icons/us.svg"
                alt=""
              />
              <h6>Inglês</h6>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ template.language }}
          </ng-container>
        </ng-container>
      </td>
      <td class="template-action">
        <button mat-button (click)="showTemplateDetails(template)">
          Selecionar
        </button>
      </td>
    </tr>
  </table>
</div>

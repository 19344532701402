import { Injector, QueryList, ViewChild, Component, Renderer2, ElementRef, ViewChildren } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { Tag, Pager, Channel, Webhook, TagService, ChannelService, AbstractComponent, WaTemplateResponse, WebhookIntegrations, WebhookRequestTypeEnum, WebhookIntegrationsService, WebhookIntegrationActionsComponent, Traduct, Company, Department, DepartmentService, TeamService, Team } from 'lib-trend-core'
import { v4 as uuidv4 } from 'uuid'
import { environment } from '../../../../../environments/environment'
import { TemplateMessage } from './webhook-integration-wa-template/webhook-integrations-wa-template-message/webhook-integrations-wa-template-message.component'

interface actionObject {
  type: string
  field: string
  value: string
  valueHtml?: string
  typeMessage?: string
  template?: object
  templateParams?: Array<string>
}

@Component({
  selector: 'webhook-integrations-form-component',
  templateUrl: './webhook-integrations-form.component.html',
  styleUrls: ['./webhook-integrations-form.component.scss'],
})
export class WebhookIntegrationsFormComponent extends AbstractComponent {
  webhookIntegrations?: WebhookIntegrations
  selectedChannel: Channel
  templateMessage: TemplateMessage

  isActive: boolean
  url: string
  webhookRequests: object
  webhookSelectedId: string
  showPreviewTemplate: boolean
  previewHeader: any
  translator!: Traduct

  requestsSelected: Array<object> = new Array<object>()
  actions: Array<string> = new Array<string>()
  channels: Array<Channel> = new Array<Channel>()
  actionsComponent: Array<WebhookIntegrationActionsComponent> =
    new Array<WebhookIntegrationActionsComponent>()

  selectedTemplate: WaTemplateResponse

  pager: Pager<Tag> = new Pager<Tag>({ perPage: 10 })
  pagerDepartment: Pager<Department> = new Pager<Department>({ perPage: 9 });

  webhooks: Array<Webhook> = new Array<Webhook>()
  optionsForSelectActionComponents: object
  showContainerEmoji: boolean = false
  cursorPosition: number

  @ViewChild('webhookContainer') webhookContainer: ElementRef
  @ViewChildren('divActions') divActions: QueryList<ElementRef>
  @ViewChildren('messageTextarea') messageTextarea: QueryList<ElementRef>

  private unlistener: () => void
  private timeOut: any = null
  private oldRequests: object
  private departments: Array<Department>

  constructor(
    injector: Injector,
    public channelService: ChannelService,
    public webhookIntegrationsService: WebhookIntegrationsService,
    public departmentService: DepartmentService,
    public teamService: TeamService,
    public tagService: TagService,
    private renderer: Renderer2
  ) {
    super(injector)
    this.createForm()
    this.getListActions()
    this.getTagList()
  }
  
  ngOnInit() {
    this.webhookIntegrations = <WebhookIntegrations>{}
    const webhookId = this.getParam('id')
    this.isNew = !webhookId ? true : false
    
    this.isActive = this.webhookIntegrations.actived

    
    if (!this.isNew) {
      this.webhookIntegrationsService
      .getById(webhookId)
      .subscribe((webhook) => {
        this.webhookIntegrations = webhook
        this.updateValuesToInput(webhook)
        this.getWebhookRequests()
      })
    } else {
      this.webhookIntegrations.uuid = uuidv4()
      this.webhookIntegrations.url = this.generateWebhookIntegrationsURLbyCode(
        this.webhookIntegrations.uuid
      )
      this.formGroup.get('url').setValue(this.webhookIntegrations.url)
      this.formGroup.get('company').setValue(this.getIDCurrentCompany())
      this.formGroup.get('uuid').setValue(this.webhookIntegrations.uuid)
      this.createActionComponent('Telefone Whatsapp')
      this.createActionComponent('Nome do Contato')
    }

  }
  
  ngAfterViewInit(): void {
    this.loadAllChannels()
    this.getDepartments()

    this.unlistener = this.renderer.listen(
      this.webhookContainer.nativeElement,
      'click',
      (e) => {
        const isOpen = this.actionsComponent.filter(
          (el) => el.requestField === true
        )

        const divActionsChildren = this.divActions
          .map((el) => Array.from(el.nativeElement.children))
          .filter((el) => el.includes(e.target))

        if (
          (e.target === this.webhookContainer.nativeElement ||
            this.divActions.find((el) => el.nativeElement === e.target) ||
            divActionsChildren.length !== 0) &&
          isOpen.length !== 0
        ) {
          this.closeSearchField()
        }
      }
    )
  }

  override ngOnDestroy(): void {
    this.unlistener()
  }

  private updateTextareaContent(index: number, html: string) {
    const element = isNaN(index) 
    ? this.messageTextarea.toArray()[0].nativeElement as HTMLAreaElement
    : this.messageTextarea.toArray()[index].nativeElement as HTMLAreaElement;
  
    element.innerHTML = html;
  }

  public generateWebhookIntegrationsURLbyCode(uuid: string): string {
    const code = super.getCodeCompanyCurrentUser()
    return `${environment.trendIntegrationsUrl}/webhook/${code}/${uuid}`
  }

  getWebhookRequests() {
    const excludeParams = new Set(['code', 'uuid'])

    this.webhookIntegrationsService
      .getEventsByUUID(this.webhookIntegrations.uuid)
      .subscribe((webhook: Array<Webhook>) => {
        webhook.map((webhook) => {
          const exist = this.webhooks.some((el) => el._id === webhook._id)
          if (!exist) {
            this.webhooks.push({
              _id: webhook._id,
              channel: webhook.channel,
              createdAt: webhook.createdAt,
              updatedAt: webhook.updatedAt,
              removed: webhook.removed,
              message: webhook.message,
              event: webhook.event,
              headers: webhook.headers,
              metadata: {
                ...webhook.metadata.query,
                ...Object.fromEntries(
                  Object.entries(webhook.metadata.params).filter(
                    (e) => !excludeParams.has(e[0])
                  )
                ),
                ...webhook.metadata.body,
              },
            })
          }
        })

        if (this.getParam('id')) {
          const webhookSelected = this.webhooks.filter(
            (wb) =>
              wb._id === (this.webhookIntegrations.webhook as unknown as string)
          )[0]
          this.webhookRequests = webhookSelected.metadata
          this.formGroup.get('tags').patchValue(webhookSelected._id)
        }
      })
  }

  getDepartments() {
    const webhookId = this.getParam('id')

    if(this.isAdmin()) {
      this.searchParams = {
        company: this.getIDCurrentCompany()
      };
      this.departmentService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
        .subscribe({
          next: (value) => {
            this.departments = value.list;

            if(!!webhookId && this.formGroup.get('attendance').value) {
              this.selectDepartment(this.formGroup.get('attendance').value)
            }
          },
          complete: () => {
            this.optionsForSelectActionComponents = {
              ...this.optionsForSelectActionComponents,
              attendance: this.departments.map(department => department.name)
            }
            
            this.loadingContent = false
          },
          error: (err) => this.alertService.error(err.error.message),
        });
    } else {
      this.searchParams = {
        company: this.getIDCurrentCompany(),
        user: this.getIDCurrentUser()
      };
      this.teamService.getDepartmentFromSupervisor(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .subscribe({
        next: (value) => {
          this.optionsForSelectActionComponents = {
            ...this.optionsForSelectActionComponents,
            attendance: value.list.map(department => department.name)
          }

          if(!!webhookId) {
            this.selectDepartment(this.formGroup.get('attendance').value)
          }
        },
        complete: () => (this.loadingContent = false),
        error: (err) => this.alertService.error(err.error.message),
      });
    }
  }

  private loadUsersByDepartment(idDepartment: string) {
    if (!idDepartment) return;
    this.teamService.getByDepartment(idDepartment).subscribe({
      next: (teams: Team[]) => {
        this.optionsForSelectActionComponents = {
          ...this.optionsForSelectActionComponents,
          user: teams.map(({agents}) => agents.map(({name}) => name))[0]
        }
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  webhookRequestSelected(index) {
    this.webhookRequests = this.webhooks[index].metadata
    this.webhookSelectedId = this.webhooks[index]._id
    this.oldRequests = this.webhookRequests
  }

  searchRequest(event, fieldName) {    
    clearTimeout(this.timeOut)
    this.timeOut = setTimeout(() => {
      if (event.target.value === '') {
        this.webhookRequests = this.oldRequests
      } else {
        let obj = {}

        if (this.webhookRequests !== undefined) {
          for (const [key, value] of Object.entries(this.webhookRequests)) {
            if (key.search(event.target.value) !== -1) {
              obj = Object.assign(obj, {
                [key]: value,
              })
            }
          }
        }

        if (Object.keys(obj).length === 0) {
          if (event.key === 'Enter') {
            this.formGroup.get('actions').value[fieldName].push({
              type: fieldName,
              requestKey: event.target.value,
              field: fieldName,
              value: event.target.value,
            })
          }
        }

        this.webhookRequests = obj
      }
    }, 500)
  }

  requestSearch(action, fieldName) {
    if (action === 'open') {
      this.actionsComponent.filter(
        (component) => component.name === fieldName
      )[0].requestField = true
    }
  }

  insertRequestList(fieldName, requestKey, requestValue) {
    if(this.formGroup.get('actions').value[fieldName][0]) {
      this.formGroup.get('actions').value[fieldName][0].value = this.formGroup.get('actions').value[fieldName][0].value + requestValue
    }

    this.formGroup.get('actions').value[fieldName].push({
      type: fieldName,
      requestKey: requestKey,
      field: fieldName,
      value: requestValue,
    })

    this.formGroup.get(fieldName).setValue(requestKey)
  }

  getCursorPosition() {
    const selection = window.getSelection()
    const range = selection?.getRangeAt(0)

    if (range) {
      clearTimeout(this.timeOut)

      this.timeOut = setTimeout(() => {
        this.cursorPosition = range.startOffset
      }, 500)
    }
  }

  insertRequestOnMessage(request, fieldName) {
    const index = fieldName.includes('-') ? fieldName.split('-')[1] : 0

    const textarea = this.messageTextarea['_results'][index].nativeElement as HTMLDivElement
    const sel = document.getSelection()
    let range

    if (
      sel &&
      sel.rangeCount > 0 &&
      (sel.focusNode?.parentElement === textarea || sel.focusNode === textarea)
    ) {
      range = sel.getRangeAt(0)
    } else {
      range = document.createRange()
      range.selectNodeContents(textarea)
      range.collapse(false)
    }

    const requestSpan = document.createElement('span')
    requestSpan.setAttribute('id', 'requestSpanField')
    requestSpan.classList.add(
      'bg-slate-200',
      'flex',
      'gap-2',
      'p-2',
      'rounded',
      'items-center',
      'w-fit',
      'h-fit'
    )
    requestSpan.innerHTML = `{${request}}
                    <span
                      class="cursor-pointer"
                    >
                      <svg
                        width="10px"
                        height="10px"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.79262 0.207379C8.06913 0.483884 8.06913 0.932187 7.79262 1.20869L5.00131 4L7.79262 6.79131C8.06913 7.06781 8.06913 7.51612 7.79262 7.79262C7.51612 8.06913 7.06781 8.06913 6.79131 7.79262L4 5.00131L1.20869 7.79262C0.932187 8.06913 0.483884 8.06913 0.207379 7.79262C-0.0691263 7.51612 -0.0691253 7.06781 0.207379 6.79131L2.99869 4L0.207379 1.20869C-0.0691259 0.932187 -0.0691266 0.483884 0.207378 0.207379C0.483883 -0.0691262 0.932187 -0.0691263 1.20869 0.207379L4 2.99869L6.79131 0.207379C7.06781 -0.0691264 7.51612 -0.0691262 7.79262 0.207379Z"
                          fill="#2B3238"
                        ></path>
                      </svg>
                    </span>`
    requestSpan.contentEditable = 'false'

    requestSpan.querySelector('span')?.addEventListener('click', () => {
      textarea.removeChild(requestSpan)
    })

    range.deleteContents()
    range.insertNode(requestSpan)

    const space = document.createTextNode('\u00A0')
    range.setStartAfter(requestSpan)
    range.setEndAfter(requestSpan)
    range.insertNode(space)

    const newRange = document.createRange()
    newRange.setStartAfter(space)
    newRange.setEndAfter(space)
    sel.removeAllRanges()
    sel.addRange(newRange)

    textarea.focus()

    this.formGroup.get('typeMessage').setErrors(null)
  }

  setSendMessageValue(event, fieldName) {
    const field = fieldName.replace('typeMessage', 'sendMessage')

    this.formGroup.get('actions').value[field] = [
      {
        type: 'message',
        typeMessage: this.formGroup.get(fieldName).value,
        field: field,
        value: event.target.textContent.replace(/\s+/g, ' ').trim(),
        valueHtml: event.target.innerHTML,
      },
    ]
  }

  validateMessage(event) {
    if (event.target.innerText === '') {
      this.formGroup.get('typeMessage').setErrors({ required: true })
      return
    }

    this.formGroup.get('typeMessage').setErrors(null)
  }

  removeRequestList(fieldName, request) {
    this.formGroup.get('actions').value[fieldName] = this.formGroup
      .get('actions')
      .value[fieldName].filter((req) => req !== request)
  }

  selectChannel(idChannel: string): void {
    this.selectedChannel = this.channels.find((c) => c._id === idChannel)
    
      if (this.selectedChannel.type === 'CLOUD_API') {
        if (this.actionsComponent.find((el) => el.name === 'typeMessage')) {
          this.removeActionComponent('typeMessage')
        }
        
        if(!this.actionsComponent.find(el => el.name === 'template')) {
          this.createActionComponent('Template')
        }
      } else {
        if (this.actionsComponent.find((el) => el.name === 'template')) {
          this.removeActionComponent('template')
        }

        if(!this.actionsComponent.find(el => el.name === 'typeMessage')){
          this.createActionComponent('Enviar mensagem')
        }
      }
  }

  onSelectTemplate(template: WaTemplateResponse) {
    this.selectedTemplate = template
    this.showPreviewTemplate = true
    this.formGroup.get('template').setValue(this.selectedTemplate)
  }

  changeTemplate() {
    this.showPreviewTemplate = false
  }

  setTemplateMessage(templateMessage: TemplateMessage) {
    this.templateMessage = templateMessage
    this.previewHeader = templateMessage.previewHeader

    this.formGroup.get('templateParams').setValue({
      params: this.templateMessage.params,
      midiaHeader: templateMessage.midiaHeader,
      requestKey: templateMessage.requestKey
    })

    this.formGroup.get('actions').value['template'] = [
      {
        type: 'message',
        typeMessage: 'template',
        field: 'template',
        template: this.formGroup.get('template').value,
        templateParams: this.templateMessage.params,
        midiaHeader: templateMessage.midiaHeader,
        requestKey: templateMessage.requestKey
      },
    ]
  }

  toggleWebhookStatus() {
    if (this.isActive && !this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup)
      this.isActive = false
      return
    }

    this.allowEdit()
  }

  allowEdit() {
    if (this.isActive) {
      this.formGroup.get('name').disable()
      this.formGroup.get('channel').disable()
      this.formGroup.get('requestType').disable()
      this.formGroup.get('tags').disable()
      this.formGroup.get('nameInput').disable()
      this.formGroup.get('template').disable()
      this.formGroup.get('templateParams').disable()
      this.formGroup.get('addLabel').disable()
      this.formGroup.get('removeLabel').disable()
      this.formGroup.get('typeMessage').disable()
      this.messageTextarea &&
        (
          this.messageTextarea['_results'].map((textarea) => textarea.nativeElement as HTMLAreaElement
        ).contentEditable = 'false')
    } else {
      this.formGroup.get('name').enable()
      this.formGroup.get('channel').enable()
      this.formGroup.get('requestType').enable()
      this.formGroup.get('tags').enable()
      this.formGroup.get('nameInput').enable()
      this.formGroup.get('template').enable()
      this.formGroup.get('templateParams').enable()
      this.formGroup.get('addLabel').enable()
      this.formGroup.get('removeLabel').enable()
      this.formGroup.get('typeMessage').enable()
      this.messageTextarea &&
        (this.messageTextarea['_results'].map((textarea) => textarea.nativeElement as HTMLAreaElement
      ).contentEditable = 'true')
    }
  }

  createActionComponent(action: string) {
    const dictionary = {
      'Telefone Whatsapp': 'phone',
      'Nome do Contato': 'nameInput',
      Template: 'template',
      'Adicionar etiqueta': 'addLabel',
      'Remover etiqueta': 'removeLabel',
      'Inscrição em sequência': 'sequencialRegistration',
      'Descadastrar em sequência': 'sequencialRemoval',
      'Enviar fluxo': 'sendFlow',
      'Enviar mensagem': 'typeMessage',
      'Atualizar campo do usuário': 'updateUser',
      'Iniciar atendimento': 'attendance'
    }

    const index = this.actionsComponent.filter(field => field.options === dictionary[action]).length

    if(index > 0) {
      const newControl = `${dictionary[action]}-${index}`
      this.formGroup.addControl(newControl, new FormControl(null, Validators.required))
      this.formGroup.get('actions').value[newControl.replace('typeMessage', 'sendMessage')] = []
    }

    if(dictionary[action] === 'attendance' && index > 0) {
      return
    }

    const actionMethods = this.componentActions(index)
    actionMethods[dictionary[action]]()
    
    if(dictionary[action] !== 'phone' && dictionary[action] !== 'nameInput') {
      if (this.formGroup.get(dictionary[action]).value === null) {
        this.formGroup.addControl(
          dictionary[action],
          new FormControl(null, Validators.required)
        )
  
        this.formGroup.controls[dictionary[action]].setValidators([
          Validators.required,
        ])
      } else {
        this.formGroup.controls[dictionary[action]].setValidators([
          Validators.required,
        ])
      }
    }
  }

  removeActionComponent(action: string, index?: number) {
    if(index !== undefined) {
      const elToRemove = this.actionsComponent[index]
      this.actionsComponent = this.actionsComponent.filter(el => el !== elToRemove)
    } else {
      this.actionsComponent = this.actionsComponent.filter(
        (el) => el.name !== action
      )
    }

    const field = action === 'typeMessage' ? 'sendMessage' : action

    this.formGroup.get('actions').value[field] = []

    this.formGroup.controls[action].clearValidators()
  }

  async copyUrl() {
    try {
      await navigator.clipboard.writeText(this.webhookIntegrations.url)
      this.alertService.info('A url do Webhook foi copiada!')
    } catch (error) {
      console.error(error.message)
    }
  }

  selectDepartment(departmentName) {
    const department = this.departments.filter(department => department.name === departmentName)[0]
    this.loadUsersByDepartment(department._id)
  }

  selectTag(fieldName, event) {
    const field = fieldName.replace('typeMessage', 'sendMessage')
    fieldName !== 'user' && this.formGroup.get(fieldName).setValue(event.target.textContent.trim())

    if (field !== 'user' && field === 'sendMessage' && this.formGroup.get('actions').value[field][0] !== undefined) {
      this.formGroup.get('actions').value[field][0].typeMessage =
        event.target.textContent.trim()
    } else if (!fieldName.includes('typeMessage') && !(fieldName === 'user')){
      this.formGroup.get('actions').value[fieldName === 'user' ? 'attendance' : fieldName] = [{
        type: fieldName.split('-')[0],
        field: fieldName,
        value: event.target.textContent.trim()
      }]
    } else if (fieldName === 'user') {
      if(this.formGroup.get('actions').value['attendance'][0]) {
        this.formGroup.get('actions').value['attendance'][0].value = {
          department: this.formGroup.get('actions').value['attendance'][0].value,
          user: event.target.textContent.trim()
        }
      }
    }
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup)
      return
    }

    let action = []

    for (const [index, element] of Object.entries(
      this.formGroup.value.actions
    )) {
      if (!!element[0]) {
        action.push({ ...element[0] })
      }
    }

    const webhook = <WebhookIntegrations>{
      uuid: this.webhookIntegrations.uuid,
      name: this.formGroup.get('name').value as string,
      url: this.formGroup.get('url').value as string,
      actived: this.isActive as boolean,
      company: <Company>{ _id: super.getIDCurrentCompany() },
      channel: <Channel>{ _id: this.selectedChannel._id },
      webhook: <Webhook>{ _id: this.webhookSelectedId },
      actions: action,
    }

    if (!this.isNew) {
      this.webhookIntegrationsService
        .update(this.webhookIntegrations._id, webhook)
        .subscribe({
          next: (value) => this.alertService.success(),
          error: (err) => this.alertService.error(err.error.message),
        })
    } else {
      this.webhookIntegrationsService.create(webhook).subscribe({
        next: (value) => this.alertService.success(),
        error: (err) => this.alertService.error(err.error.message),
      })
    }

    this.router.navigate(['integrations/webhook/list'])
  }

  addEmoji(event: any): void {
    const index = this.actionsComponent.filter(field => field.name.includes('typeMessage')).length

    const textarea = this.messageTextarea['_results'][index-1].nativeElement as HTMLAreaElement
    const currentText = textarea.innerHTML || ''

    textarea.innerHTML = currentText + event.emoji.native
  }

  updateMessage(fieldName) {
    const index = this.actionsComponent.filter(field => field.name.includes('typeMessage')).length

    const textarea = this.messageTextarea['_results'][index-1].nativeElement as HTMLTextAreaElement
    const messageWithoutLineBreaks = textarea.value
    this.formGroup.get('actions').value[fieldName] = [
      {
        value: messageWithoutLineBreaks,
      },
    ]
  }

  toogleContainerEmoji(): void {
    this.showContainerEmoji = !this.showContainerEmoji
  }

  applyFormat(format: string, fieldName) {
    const index = this.actionsComponent.filter(field => field.name.includes('typeMessage')).length

    const textarea = this.messageTextarea['_results'][index-1].nativeElement as HTMLTextAreaElement
    const sel = document.getSelection()
    const range = sel.getRangeAt(0)
    const start = range.startOffset
    const end = range.endOffset
    const selectedText = textarea.textContent.substring(start, end)

    if (!selectedText) return

    let formattedText = ''

    switch (format) {
      case 'bold':
        formattedText = `*${selectedText}*`
        break
      case 'italic':
        formattedText = `_${selectedText}_`
        break
      case 'underline':
        formattedText = `~${selectedText}~`
        break
      default:
        return
    }

    textarea.textContent =
      textarea.textContent.substring(0, start) +
      formattedText +
      textarea.textContent.substring(end)

    textarea.focus()
    this.updateMessage(fieldName)
  }

  private getTagList() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    }

    this.tagService
      .getAll(
        this.pager.page,
        this.pager.perPage,
        this.searchString,
        this.searchParams
      )
      .subscribe({
        next: (value) => {
          this.optionsForSelectActionComponents = {
            ...this.optionsForSelectActionComponents,
            addLabel: value.list.map((tag) => tag.title),
            removeLabel: value.list.map((tag) => tag.title),
          }
        },
        complete: () => (this.loadingContent = false),
        error: (err) => this.alertService.error(err.error.message),
      })
  }

  private closeSearchField() {
    this.actionsComponent.filter(component => component.requestField === true).map((component) => {
      if(this.formGroup.get('actions').value[component.name].length > 0) {
        this.formGroup.get(component.name).setErrors(null)
        component.requestField = false
        return
      }

      this.formGroup.get(component.name).setErrors({required: true})
    })
  }

  private componentActions(index?: number) {
    return {
      phone: () => {
        this.actionsComponent.push({
          name: 'phone',
          label: 'Telefone Whatsapp',
          text: 'Selecione o campo com o número de WhatsApp da lista de requisições à esquerda. O código do país (DDI - country code) deve ser inserido junto com o telefone. Caso o mesmo estiver em um campo separado, adicione ambos os campos. Se o DDI não estiver no campo do número, nem em um campo separado, por favor insira-o manualmente.',
          required: true,
          search: true,
          requestField: false,
        })
      },
      nameInput: () => {
        this.actionsComponent.push({
          name: 'nameInput',
          label: 'Criar/Atualizar Nome do Contato',
          text: 'Selecione o campo com o nome completo do contato da lista de requisições à esquerda. O contato será cadastrado em sua audiência caso o mesmo não esteja cadastrado. No caso do nome estar separado em dois campos, primeiro nome e sobrenome, adicione ambos os campos.',
          required: true,
          search: true,
          requestField: false,
        })
      },
      template: () => {
        this.actionsComponent.push({
          name: 'template',
          label: 'Template da campanha',
          text: 'Selecione o template da campanha e preencha as variáveis necessárias para o template escolhido.',
          required: true,
          template: true,
        })
      },
      addLabel: () => {
        this.actionsComponent.push({
          name: index > 0 ? `addLabel-${index}` : 'addLabel',
          options: 'addLabel',
          label: 'Adicionar etiqueta',
          text: 'Selecione a etiqueta que será adicionada ao contato após o mesmo ser cadastrado em sua audiência.',
          required: false,
          select: true,
        })
      },
      removeLabel: () => {
        this.actionsComponent.push({
          name: index > 0 ? `removeLabel-${index}` :'removeLabel',
          options: 'removeLabel',
          label: 'Remover etiqueta',
          text: 'Selecione a etiqueta que será removida do contato. Caso o contato não possua a etiqueta, essa ação será ignorada e pulada.',
          required: false,
          select: true,
        })
      },
      sequencialRegistration: () => {
        this.actionsComponent.push({
          name: 'sequencialRegistration',
          label: 'Inscrever em sequência',
          text: 'Selecione em qual sequência você deseja inscrever este contato. A execução dos fluxos desta sequência irá respeitar a programação da mesma.',
          required: false,
          select: true,
        })
      },
      sequencialRemoval: () => {
        this.actionsComponent.push({
          name: 'sequencialRemoval',
          label: 'Remover de sequência',
          text: 'Selecione de qual sequência você deseja remover este contato. Caso o contato não esteja inscrito na sequência essa ação será ignorada e pulada.',
          required: false,
          select: true,
        })
      },
      sendFlow: () => {
        this.actionsComponent.push({
          name: 'sendFlow',
          label: 'Enviar Fluxo',
          text: 'Selecione qual fluxo você deseja enviar para o contato. Coloque essa ação como a última ação da lista de ações deste webhook.',
          required: false,
          select: true,
        })
      },
      typeMessage: () => {
        this.actionsComponent.push({
          name: index > 0 ? `typeMessage-${index}` : 'typeMessage',
          options: 'typeMessage',
          label: 'Enviar mensagem',
          text: 'Você pode enviar diferentes tipos de mensagens com esta ação. Selecione o tipo de mensagem que você deseja enviar e adicione o conteúdo da mesma abaixo. Para Imagem, Áudio, Vídeo ou Documento por favor insira o link do arquivo do mesmo terminado com o tipo da extensão do arquivo. (Ex: "https://yourfilelink.com/file/image.jpg")',
          required: false,
          select: true,
          message: true,
          requestField: false,
        })
        this.optionsForSelectActionComponents = {
          ...this.optionsForSelectActionComponents,
          typeMessage: ['Texto', 'Imagem', 'Áudio', 'Vídeo', 'Documento'],
        }
      },
      updateUser: () => {
        this.actionsComponent.push({
          name: 'updateUser',
          label: 'Atualizar campo do usuário',
          text: 'Selecione o campo de usuário que você deseja atualizar com qualquer valor escolhido da lista de requisições à esquerda.',
          required: false,
          search: true,
          select: true,
          requestField: false,
        })
      },
      attendance: () => {
        this.actionsComponent.push({
          name: 'attendance',
          options: 'attendance',
          label: 'Iniciar atendimento',
          text: 'Selecione um departamento para dar início a um atendimento, você também pode selecionar um atendente específico, mas essa parte é opcional',
          required: false,
          search: false,
          select: true,
          requestField: false,
        })
      },
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      company: [null, Validators.required],
      uuid: [null, Validators.required],
      name: [null, Validators.required],
      requestType: [WebhookRequestTypeEnum.DEFAULT, Validators.required],
      request: [null],
      tags: [null],
      channel: [null, Validators.required],
      phone: [null],
      nameInput: [null],
      template: [null],
      templateParams: [null],
      addLabel: [null],
      removeLabel: [null],
      sequencialRegistration: [null],
      sequencialRemoval: [null],
      sendFlow: [null],
      typeMessage: [null],
      updateUser: [null],
      attendance: [null],
      user: [null],
      url: [null, Validators.required],
      actions: [
        {
          phone: [],
          nameInput: [],
          addLabel: [],
          removeLabel: [],
          sequencialRegistration: [],
          sequencialRemoval: [],
          sendFlow: [],
          sendMessage: [],
          updateUser: [],
          template: [],
          attendance: []
        },
      ],
    })
  }

  private loadAllChannels() {
    this.channelService.getList().subscribe({
      next: (channels) => {
        this.channels = channels
      },
      complete: () => {
        this.selectedChannel = !this.isNew ? this.channels.find((c) => c._id === this.webhookIntegrations.channel as unknown) : this.selectedChannel
      }
    })
  }

  private getListActions(): void {
    const actions = [
      'Adicionar etiqueta',
      'Remover etiqueta',
      // 'Inscrição em sequência',
      // 'Descadastrar em sequência',
      // 'Enviar fluxo',
      'Enviar mensagem',
      // 'Atualizar campo do usuário',
      'Iniciar atendimento'
    ]

    this.actions.push(...actions)
  }

  private updateValuesToInput(webhook: WebhookIntegrations) {
    if (webhook) {
      this.formGroup.patchValue({
        ...Object.fromEntries(
          Object.entries(webhook).filter(
            (e) => e[0] != 'actions'
          )
        ),
      })

      this.isActive = webhook.actived
      
      this.webhookSelectedId = webhook
        .webhook as unknown as string

      webhook.actions.map((action: actionObject) => {
        const field =
          action.field.includes('sendMessage') ? action.field.replace('sendMessage', 'typeMessage') : action.field

        const index = Number(field.split('-')[1])

        this.formGroup.get('actions').value[action.field] ? this.formGroup.get('actions').value[action.field].push(action) : this.formGroup.get('actions').value[action.field] = [action]

        if (field.includes('typeMessage')) {
          if(this.formGroup.get(field)) {
            this.formGroup.get(field).setValue(action.typeMessage) 
          } else {
            this.formGroup.addControl(field, new FormControl(null, Validators.required))
            this.formGroup.get(field).setValue(action.typeMessage) 
          }
        } else {
          if(this.formGroup.contains(action.field)){
            if(action.field === 'attendance' && action.value['department']) {
              this.formGroup.get(action.field).setValue(action.value['department'])
              this.formGroup.get('user').setValue(action.value['user'])
            } else {
              this.formGroup.get(action.field).setValue(action.value)
            }                  
          } else { 
            if(action.field !== 'phone') {
              if(action.field !== 'nameInput') {
                this.formGroup.addControl(
                  action.field,
                  new FormControl(action.value, Validators.required)
                )  
              }
            }
          }
        }
        
        if (action.field === 'template') {
          this.onSelectTemplate(action.template as WaTemplateResponse)
        }
        

        if (field.includes('typeMessage')) {
          this.messageTextarea.changes.subscribe(() => {
            this.updateTextareaContent(index, action.valueHtml);
          });
        }

        const actionMethods = this.componentActions(index)
        actionMethods[field.includes('-') ? field.split('-')[0] : field]()
      })

      this.allowEdit()
    }
  }
}
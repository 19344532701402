import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { IntegrationsComponent } from './integrations.component'
import { WebhookIntegrationsFormComponent } from './webhook-integrations/webhook-integrations-form/webhook-integrations-form.component'
import { WebhookIntegrationsListComponent } from './webhook-integrations/webhook-integrations-list/webhook-integrations-list.component'

const routes: Routes = [
  { path: '', component: IntegrationsComponent },
  { path: 'webhook/list', component: WebhookIntegrationsListComponent },
  { path: 'webhook/create', component: WebhookIntegrationsFormComponent },
  { path: 'webhook/:id/edit', component: WebhookIntegrationsFormComponent },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IntegrationsRoutingModule { }

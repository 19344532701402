import { Component, OnInit } from '@angular/core'
import { AbstractComponent } from 'lib-trend-core'

@Component({
  selector: 'integrations-component',
  templateUrl: 'integrations.component.html',
  styleUrl: 'integrations.component.scss',
})
export class IntegrationsComponent extends AbstractComponent implements OnInit {
  ngOnInit(): void { }
}

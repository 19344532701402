<div #webhookContainer class="flex flex-1 flex-col px-5">
  <div class="flex flex-col justify-start">
    <div class="mb-4">
      <h1 class="font-black text-[20px] text-black">Adicionar Webhook</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">
        Preencha os dados e crie um novo webhook
      </h6>
      <hr />
    </div>
  </div>

  <div class="flex justify-end items-center text-[16px] text-black gap-2">
    <div class="w-full flex flex-col flex-1 my-4">
      <p class="flex items-center gap-2">
        <span class="font-black text-black text-[16px]"> Url do Webhook: </span>
        <span class="text-zinc-400 text-[12px]">{{
          webhookIntegrations.url
        }}</span>

        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="copyIcon"
          class="cursor-pointer"
          (click)="copyUrl()"
        >
          <path
            d="M4.88653 8.62636H13.4871C14.5289 8.62636 15.3737 9.47115 15.3737 10.5129V19.1135C15.3737 20.1552 14.5289 21 13.4871 21H4.88653C3.84479 21 3 20.1552 3 19.1135V10.5121V10.5129C3 10.0122 3.19854 9.53283 3.55291 9.17926C3.90649 8.82568 4.38584 8.62636 4.88653 8.62636Z"
            fill="#644EEF"
          ></path>
          <path
            d="M19.1135 3H10.5129C9.47195 3.00237 8.62953 3.84558 8.62637 4.88653V7.50074L13.4871 7.49995C14.286 7.50232 15.0509 7.8203 15.6157 8.38429C16.1797 8.94907 16.4977 9.71395 16.5 10.5129V15.3736H19.1143H19.1135C20.1544 15.3705 20.9968 14.528 21 13.4871V4.88651C20.9968 3.84556 20.1544 3.00316 19.1135 3Z"
            fill="#644EEF"
          ></path>
        </svg>
      </p>
    </div>

    <p class="font-black">{{ isActive ? "Ativo" : "Modo Teste" }}.</p>
    <span>{{
      isActive ? "O webhook está funcionando" : "O webhook não está funcionando"
    }}</span>
    <mat-slide-toggle
      [(ngModel)]="isActive"
      class="example-slide"
      color="#4213F6"
      (click)="toggleWebhookStatus()"
      [disabled]="!isAdmin()"
    >
    </mat-slide-toggle>

    <button
      class="w-[fit-content] ml-3 px-3 h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]"
    >
      Mostrar Análise
    </button>
  </div>

  <div class="flex w-full flex-col">
    <div class="flex flex-col">
      <form [formGroup]="formGroup">
        <div class="flex flex-1 item-center gap-2 mb-4 mt-4">
          <div class="flex flex-col flex-1">
            <label class="font-semibold text-black">Nome do Webhook *</label>
            <input
              class="input-field"
              maxlength="100"
              formControlName="name"
              placeholder="Nome do Webhook"
              [ngClass]="{'opacity-20': this.isActive}"
            />
            <field-error-component
              [field]="'name'"
              [label]="'Nome do Webhook'"
              [formGroup]="formGroup"
            ></field-error-component>
          </div>

          <div class="flex gap-3 w-[29.5%]">
            <div class="flex flex-col w-[100%]">
              <label class="font-semibold text-black">Canal *</label>

              <mat-select
                class="input-field max-w-[100%]"
                (selectionChange)="selectChannel($event.value)"
                #channel
                formControlName="channel"
                placeholder="Selecione"
                [ngClass]="{'opacity-20': this.isActive}"
              >
                <mat-option
                  *ngFor="let channel of channels"
                  [value]="channel._id"
                  >{{ channel.name }}</mat-option
                >
              </mat-select>
              <field-error-component
                [field]="'channel'"
                [label]="'Canal'"
                [formGroup]="formGroup"
              ></field-error-component>
            </div>
          </div>
        </div>
        <div *ngIf="selectedChannel" class="flex w-full pt-4 gap-4 mb-6" [ngClass]="{'opacity-20': this.isActive}">
          <div class="w-[400px] px-3 py-3 flex flex-col bg-white rounded-lg">
            <h4 class="font-black text-black">Requisições</h4>
            <span class="text-black px-0 py-0 text-[12px]">
              Selecione uma das requisições que deseja usar para ações
            </span>
            <mat-radio-group
              class="w-full flex justify-start pt-3 pb-3 gap-4"
              color="primary"
              aria-label="Selecione o tipo de requisição"
              formControlName="requestType"
            >
              <mat-radio-button value="DEFAULT">Padrão</mat-radio-button>
              <!-- <mat-radio-button value="INTEGRATION">
                Integração
              </mat-radio-button> -->
            </mat-radio-group>

            <div class="flex items-center gap-2">
              <mat-select class="input-field" formControlName="tags">
                @for(request of webhooks; track $index) {
                <mat-option
                  [value]="request._id"
                  (click)="webhookRequestSelected($index)"
                >
                  {{ $index + 1 }}
                </mat-option>
                }
              </mat-select>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="10px"
                y="10px"
                viewBox="0 0 122.88 118.66"
                style="enable-background: new 0 0 122.88 118.66"
                xml:space="preserve"
                width="24"
                height="24"
                class="cursor-pointer"
                (click)="this.isActive ? '' : getWebhookRequests()"
              >
                <g>
                  <path
                    d="M106.2,22.2c1.78,2.21,3.43,4.55,5.06,7.46c5.99,10.64,8.52,22.73,7.49,34.54c-1.01,11.54-5.43,22.83-13.37,32.27 c-2.85,3.39-5.91,6.38-9.13,8.97c-11.11,8.93-24.28,13.34-37.41,13.22c-13.13-0.13-26.21-4.78-37.14-13.98 c-3.19-2.68-6.18-5.73-8.91-9.13C6.38,87.59,2.26,78.26,0.71,68.41c-1.53-9.67-0.59-19.83,3.07-29.66 c3.49-9.35,8.82-17.68,15.78-24.21C26.18,8.33,34.29,3.76,43.68,1.48c2.94-0.71,5.94-1.18,8.99-1.37c3.06-0.2,6.19-0.13,9.4,0.22 c2.01,0.22,3.46,2.03,3.24,4.04c-0.22,2.01-2.03,3.46-4.04,3.24c-2.78-0.31-5.49-0.37-8.14-0.2c-2.65,0.17-5.23,0.57-7.73,1.17 c-8.11,1.96-15.1,5.91-20.84,11.29C18.43,25.63,13.72,33,10.62,41.3c-3.21,8.61-4.04,17.51-2.7,25.96 c1.36,8.59,4.96,16.74,10.55,23.7c2.47,3.07,5.12,5.78,7.91,8.13c9.59,8.07,21.03,12.15,32.5,12.26c11.47,0.11,23-3.76,32.76-11.61 c2.9-2.33,5.62-4.98,8.13-7.97c6.92-8.22,10.77-18.09,11.66-28.2c0.91-10.37-1.32-20.99-6.57-30.33c-1.59-2.82-3.21-5.07-5.01-7.24 l-0.53,14.7c-0.07,2.02-1.76,3.6-3.78,3.52c-2.02-0.07-3.6-1.76-3.52-3.78l0.85-23.42c0.07-2.02,1.76-3.6,3.78-3.52 c0.13,0,0.25,0.02,0.37,0.03l0,0l22.7,3.19c2,0.28,3.4,2.12,3.12,4.13c-0.28,2-2.12,3.4-4.13,3.12L106.2,22.2L106.2,22.2z"
                  />
                </g>
              </svg>
            </div>

            <div class="w-full h-[200px] input-field mt-4 overflow-y-scroll">
              @for(request of webhookRequests | keyvalue; track $index){
              <p
                class="text-[14px] text-zinc-400 flex flex-row gap-1 pt-1 first:pt-0"
              >
                <span class="font-black text-black rounded"
                  >{{ request.key }}:</span
                >
                <span class="text-zinc-400">{{ request.value }}</span>
              </p>
              }
            </div>
          </div>
          <div class="w-full h-[fit-content] flex flex-col gap-2">
            <div
              class="px-3 py-3 flex flex-1 justify-between items-center bg-white rounded-lg"
            >
              <h4 class="font-black text-black text-[20px]">
                Ações
                <sup class="text-[14px] text-zinc-400">{{
                  actionsComponent.length
                }}</sup>
              </h4>
              <div class="flex">
                <button
                  type="button"
                  [matMenuTriggerFor]="newActions"
                  class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm font-medium text-gray-900 rounded-lg global-filter"
                  [disabled]="this.isActive"
                >
                  <span> Adicionar novas ações + </span>
                </button>

                <mat-menu #newActions="matMenu">
                  @for (action of actions; track $index) {
                  @if(selectedChannel.type === 'CLOUD_API') { @if(action !==
                  "Enviar mensagem") {
                  <button mat-menu-item (click)="createActionComponent(action)">
                    {{ action }}
                  </button>
                  } } @else {
                  <button mat-menu-item (click)="createActionComponent(action)">
                    {{ action }}
                  </button>
                  } }
                </mat-menu>
              </div>
            </div>

            @for(action of actionsComponent; track $index) {
            <div
              class="flex flex-1 flex-col px-3 py-3 bg-white rounded-lg"
            >
              @if(selectedChannel) {
              <div>
                <div #divActions>
                  <label
                    class="font-black text-black text-[20px] flex justify-between"
                    >{{ action.label }}
                    <span
                      *ngIf="!action.required"
                      class="text-red-700 cursor-pointer"
                      (click)="
                        this.isActive ? '' : removeActionComponent(action.name, $index)
                      "
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.89362 6.10035H8.01797V5.20035C8.01797 3.98729 9.03007 3 10.2743 3H13.7263C14.9706 3 15.9827 3.98732 15.9827 5.20035V6.10035H19.107C19.6009 6.10035 20 6.48965 20 6.97148C20 7.45331 19.6009 7.84262 19.107 7.84262H18.6305V18.132C18.6313 19.7166 17.3157 21 15.6967 21H8.30404C6.68498 21 5.36946 19.7166 5.36946 18.132V7.84262H4.893C4.39908 7.84262 4 7.45405 4 6.97148C4.00076 6.48965 4.39984 6.10035 4.89376 6.10035H4.89362ZM14.1967 5.20035C14.1967 4.945 13.9881 4.74146 13.7263 4.74146H10.2743C10.0118 4.74146 9.80317 4.945 9.80317 5.20035V6.10035H14.1968L14.1967 5.20035ZM13.3273 16.7842C13.3273 17.266 13.7256 17.6553 14.2203 17.6553C14.7142 17.6553 15.1133 17.266 15.1133 16.7842V10.3161C15.1133 9.83429 14.7142 9.44498 14.2203 9.44498C13.7263 9.44498 13.3273 9.83429 13.3273 10.3161V16.7842ZM8.88741 16.7842C8.88741 17.266 9.28649 17.6553 9.78041 17.6553C10.2743 17.6553 10.6726 17.266 10.6726 16.7842V10.3161C10.6726 9.83429 10.2743 9.44498 9.78041 9.44498C9.28649 9.44498 8.88741 9.83429 8.88741 10.3161V16.7842Z"
                          fill="#E24B41"
                        ></path>
                      </svg>
                    </span>
                  </label>
                  <p class="py-2 mb-3 font-normal text-[14px] text-mutted">
                    {{ action.text }}
                  </p>
                </div>
                <div
                  #requestField
                  id="{{ 'field-' + action.name }}"
                  class="pl-10 mb-2 flex flex-1 gap-2 p-3 rounded-lg"
                  style="border: 1px solid #bdbdbd"
                  *ngIf="action.search"
                  (click)="
                    this.isActive ? '' : requestSearch('open', action.name)
                  "
                >
                  @for(request of formGroup.get("actions").value[action.name];
                  track $index) {
                  <span
                    class="bg-slate-200 flex gap-2 p-2 rounded items-center"
                    id="requestList"
                  >
                    {{ request.requestKey }}

                    <span
                      class="cursor-pointer"
                      (click)="
                        this.isActive
                          ? ''
                          : removeRequestList(action.name, request)
                      "
                    >
                      <svg
                        width="10px"
                        height="10px"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7.79262 0.207379C8.06913 0.483884 8.06913 0.932187 7.79262 1.20869L5.00131 4L7.79262 6.79131C8.06913 7.06781 8.06913 7.51612 7.79262 7.79262C7.51612 8.06913 7.06781 8.06913 6.79131 7.79262L4 5.00131L1.20869 7.79262C0.932187 8.06913 0.483884 8.06913 0.207379 7.79262C-0.0691263 7.51612 -0.0691253 7.06781 0.207379 6.79131L2.99869 4L0.207379 1.20869C-0.0691259 0.932187 -0.0691266 0.483884 0.207378 0.207379C0.483883 -0.0691262 0.932187 -0.0691263 1.20869 0.207379L4 2.99869L6.79131 0.207379C7.06781 -0.0691264 7.51612 -0.0691262 7.79262 0.207379Z"
                          fill="#2B3238"
                        ></path>
                      </svg>
                    </span>
                  </span>
                  }
                </div>
                <div *ngIf="action.requestField">
                  <input
                    type="search"
                    #searchInput
                    id="{{ 'inputSearch-' + action.name }}"
                    class="pl-10"
                    placeholder="Pesquise por palavra-chave..."
                    style="
                      width: 100%;
                      padding: 1rem;
                      box-sizing: border-box;
                      border: 1px solid #bdbdbd;
                      border-radius: 0.5rem 0.5rem 0 0;
                    "
                    *ngIf="action.search && action.name !== 'sendMessage'"
                    [formControlName]="action.name"
                    (keydown)="searchRequest($event, action.name)"
                    (click)="searchRequest($event, action.name)"
                  />
                  <div
                    #searchContainer
                    id="{{ 'search-' + action.name }}"
                    class="w-full h-[200px] overflow-y-scroll"
                    style="
                      padding: 1rem;
                      box-sizing: border-box;
                      border: 1px solid #bdbdbd;
                      border-top: none;
                      border-radius: 0 0 0.5rem 0.5rem;
                    "
                  >
                    @for(request of webhookRequests | keyvalue; track $index){
                    <div
                      class="text-[14px]flex flex-row gap-1 pt-1 first:pt-0"
                      (click)="
                        this.isActive
                          ? ''
                          : insertRequestList(
                              action.name,
                              request.key,
                              request.value
                            )
                      "
                      id="requestText"
                    >
                      <span
                        class="w-full font-black text-black cursor-pointer"
                        #requestKeySpan
                        id="{{ 'requestKey-' + action.name }}"
                      >
                        {{ request.key }}:

                        <span class="text-zinc-400 font-normal">
                          {{ request.value }}
                        </span>
                      </span>
                    </div>
                    }
                  </div>
                </div>

                <mat-select
                  class="input-field"
                  [formControlName]="action.name"
                  *ngIf="action.select && !loadingContent"
                >
                  <mat-option
                    *ngFor="
                      let option of optionsForSelectActionComponents[
                        action.options
                      ] | keyvalue
                    "
                    [value]="option.value"
                    (click)="selectTag(action.name, $event); action.name === 'attendance' ? selectDepartment(option.value) : ''"
                  >
                    {{ option.value }}
                  </mat-option>
                </mat-select>
                <mat-select
                  class="input-field mt-2"
                  formControlName="user"
                  *ngIf="action.select && action.name === 'attendance'"
                >
                  <mat-option
                    *ngFor="
                      let option of optionsForSelectActionComponents[
                        'user'
                      ] | keyvalue
                    "
                    [value]="option.value"
                    (click)="selectTag('user', $event)"
                  >
                    {{ option.value }}
                  </mat-option>
                </mat-select>

                <div
                  *ngIf="
                    selectedChannel &&
                    selectedChannel.type === 'CLOUD_API' &&
                    action.template
                  "
                  class="rounded-lg mt-[15px] shadow-campaign-template"
                >
                  <webhook-integration-wa-template
                    *ngIf="!showPreviewTemplate"
                    [channel]="selectedChannel"
                    (onSelectTemplate)="
                      this.isActive ? '' : onSelectTemplate($event)
                    "
                  />

                  <div *ngIf="showPreviewTemplate">
                    <div
                      class="w-full flex flex-1 justify-between items-center mb-4"
                    >
                      <h1 class="font-black text-[1.5rem] text-black">
                        Personalize o template
                      </h1>
                      <button
                        class="rounded-lg"
                        style="padding: 0.5rem; border: 1px solid #bdbdbd"
                        (click)="this.isActive ? '' : changeTemplate()"
                      >
                        Trocar template
                      </button>
                    </div>
                    <webhook-integration-wa-template-message
                      [channel]="selectedChannel"
                      [template]="selectedTemplate"
                      [requests]="webhookRequests"
                      (templateParams)="
                        this.isActive ? '' : setTemplateMessage($event)
                      "
                      [isActive]="this.isActive"
                    />
                  </div>
                </div>
                <div
                  *ngIf="
                    selectedChannel &&
                    (selectedChannel.type === 'EVOLUTION_API' || selectedChannel.type === 'EVOLUTION_GO_API') &&
                    action.message
                  "
                  class="rounded-lg mt-[15px] shadow-campaign-template"
                >
                  <div class="textarea-container">
                    <div
                      #messageTextarea
                      class="input-field h-[fit-content] flex items-center"
                      [id]="['messageTextarea' + $index]"
                      maxlength="320"
                      (blur)="setSendMessageValue($event, action.name)"
                      (focusout)="validateMessage($event)"
                      contenteditable="true"
                    ></div>

                    <div
                      class="icon-container flex items-center justify-end mt-2"
                    >
                      <mat-icon
                        (click)="this.isActive ? '' : applyFormat('bold', action.name)"
                        class="cursor-pointer ml-2"
                      >
                        format_bold
                      </mat-icon>
                      <mat-icon
                        (click)="this.isActive ? '' : applyFormat('italic', action.name)"
                        class="cursor-pointer ml-2"
                      >
                        format_italic
                      </mat-icon>
                      <mat-icon
                        (click)="this.isActive ? '' : applyFormat('underline', action.name)"
                        class="cursor-pointer ml-2"
                      >
                        format_underline
                      </mat-icon>
                      <mat-icon
                        (click)="this.isActive ? '' : toogleContainerEmoji()"
                        class="cursor-pointer ml-2"
                      >
                        sentiment_satisfied
                      </mat-icon>
                      <div class="relative" id="emoji-container">
                        <ng-container *ngIf="showContainerEmoji">
                          <emoji-mart
                            (emojiSelect)="
                              this.isActive ? '' : addEmoji($event)
                            "
                            [i18n]="translator"
                            [darkMode]="false"
                            class="absolute bottom-300 right-[40px] lg:bottom-100"
                          >
                          </emoji-mart>
                        </ng-container>
                      </div>
                      <button
                        [matMenuTriggerFor]="menuRequest"
                        class="w-[fit-content] ml-3 px-2 py-2 flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]"
                      >
                        Adicionar requisições
                      </button>
                    </div>
                  </div>
                  <ng-container class="flex flex-1">
                    <mat-menu #menuRequest="matMenu">
                      @for(request of webhookRequests | keyvalue; track $index){
                      <button
                        class="text-center"
                        mat-menu-item
                        (click)="insertRequestOnMessage(request.key, action.name)"
                      >
                        {{ request.key }}
                      </button>
                      }
                    </mat-menu>
                  </ng-container>
                </div>
                <field-error-component
                  [field]="action.name"
                  [label]="action.label"
                  [formGroup]="formGroup"
                ></field-error-component>
              </div>
              }
            </div>
          }
          </div>
        </div>
      </form>
    </div>
  </div>
  <div
    class="flex flex-1 border-t-2 border-gray-200 justify-end items-center w-full gap-4 mt-3 mb-4"
    *ngIf="selectedChannel"
  >
    <button
      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      (click)="save()"
      *ngIf="isAdmin()"
    >
      Salvar
    </button>
  </div>
</div>
